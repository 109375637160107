/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
    'use strict';

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.

    var Sage = {

        // All Pages
        'common': {
            init: function () {

                // Hamburger Menu
                (function () {
                    $('.header').on('click', '.hamburger', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        if ($(this).hasClass('is-active')) {
                            $('.mobile-menu').css({'right': '0', 'position': 'absolute'});
                        } else {
                            $('.mobile-menu').css({'right': '-100%', 'position': 'fixed'});
                        }
                    });
                    $('.mobile-menu .close').on('click', function (e) {
                        e.preventDefault();
                        $('.mobile-menu').css({'right': '-100%', 'position': 'fixed'});
                        $('.hamburger').removeClass('is-active');
                    });
                    $('html').on('click', function (e) {
                        $('.mobile-menu').css({'right': '-100%', 'position': 'fixed'});
                        $('.hamburger').removeClass('is-active');
                    });
                    $('.mobile-menu').on('click', function (e) {
                        e.stopPropagation();
                    });


                    function subMenu(){
                        if ($(window).width() < 960) {
                            $('.mobile-menu nav ul li .expand').unbind().on('click', function (event){
                                event.preventDefault();
                                $(this).find('a').toggleClass('open');
                                $(this).next().slideToggle(300);
                            });
                        }
                    }
                    subMenu();

                    $(window).on('resize', function(){
                        subMenu();
                    });


                })();

                // Search Dropdown
                (function () {
                    $('.search-btn').on('click', function (e) {
                        e.preventDefault();
                        $('.search-cnt').slideDown(300);
                    });
                    $('.search-cnt .close').on('click', function (e) {
                        e.preventDefault();
                        $('.search-cnt').slideUp(300);
                    });
                    $('.offsite').on('click', function (e) {
                        e.preventDefault();
                        $('.onsite').removeClass('active');
                        $(this).addClass('active');
                        $('.search-offsite').show();
                        $('.search-onsite').hide();

                        $('.search-cnt .search-offsite input[type=text]').val($('.search-cnt .search-onsite input[type=text]').val());
                        $('.mobile-menu .search-offsite input[type=text]').val($('.mobile-menu .search-onsite input[type=text]').val());
                    });
                    $('.onsite').on('click', function (e) {
                        e.preventDefault();
                        $('.offsite').removeClass('active');
                        $(this).addClass('active');
                        $('.search-offsite').hide();
                        $('.search-onsite').show();

                        console.log($('.search-cnt .search-offsite input[type=text]').val());

                        $('.search-cnt .search-onsite input[type=text]').val($('.search-cnt .search-offsite input[type=text]').val());
                        $('.mobile-menu .search-onsite input[type=text]').val($('.mobile-menu .search-offsite input[type=text]').val());
                    });
                })();

                // Scroll To
                (function () {
                    $('.scrollto').on('click', function (e) {
                        e.preventDefault();
                        var section = $(this).attr('href');
                        $('html, body').animate({
                            scrollTop: $(section).offset().top
                        }, 1000);
                    });
                })();

            },
        },


        // Home Page
        'page_template_template_home': {
            init: function () {

                $(function () {
                    $(".typed").typed({
                        strings: rotating_words,
                        typeSpeed: 300,
                        backSpeed: 300,
                        backDelay: 500,
                        loop: true,
                    });
                });

                var event_swiper = new Swiper('#slider .swiper-container', {
                    slidesPerView: 1.55,
                    loop: true,
                    speed: 1200,
                    initialSlide: 2,
                    loopAdditionalSlides: 1,
                    centeredSlides: true,
                    spaceBetween: 35,
                    simulateTouch: false,
                    queueStartCallbacks: true,
                    queueEndCallbacks: true,
                    breakpoints: {
                        1980: {
                            spaceBetween: 30,
                        },
                        1540: {
                            spaceBetween: 25,
                        },
                        1280: {
                            spaceBetween: 20,
                        },
                        900: {
                            spaceBetween: 15,
                        },
                        660: {
                            slidesPerView: 1,
                            initialSlide: 1,
                            spaceBetween: 0,
                        },
                    },
                });
                $('.prev').on('click', function (e) {
                    e.preventDefault();
                    event_swiper.slidePrev();
                });
                $('.next').on('click', function (e) {
                    e.preventDefault();
                    event_swiper.slideNext();
                });

                var scrollMagicController = new ScrollMagic.Controller();

                $('.section').each(function () {
                    var section = $(this).attr('id');
                    var section_height = $('#' + section).height() * 2;
                    var tween = TweenMax.staggerFromTo('#' + section + " .slider-line", 6, {height: 0}, {
                        height: '100%',
                        ease: Back.easeOut
                    });
                    var scene = new ScrollMagic.Scene({
                        triggerElement: '#' + section,
                        duration: section_height,
                    }).setTween(tween).addTo(scrollMagicController);
                });

                $('.load-btn').click(function () {
                    var btn = $(this);
                    var page = btn.data('page');
                    var url = btn.data('url')
                    $.ajax({
                        type: 'GET',
                        url: url,
                        data: {page: page},
                        success: function (data) {
                            if (data !== '' && typeof data != 'undefined') {
                                btn.before(data);
                                btn.data('page', page + 1);
                            }
                            else {
                                btn.remove();
                            }
                        }
                    })
                })

            },
        },


        // About Page
        'page_template_template_about': {
            init: function () {

                function initialize() {
                    var styles = [{
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [{"color": "#d3d3d3"}]
                    }, {
                        "featureType": "transit",
                        "stylers": [{"color": "#808080"}, {"visibility": "off"}]
                    }, {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [{"visibility": "on"}, {"color": "#b3b3b3"}]
                    }, {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [{"color": "#ffffff"}]
                    }, {
                        "featureType": "road.local",
                        "elementType": "geometry.fill",
                        "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"weight": 1.8}]
                    }, {
                        "featureType": "road.local",
                        "elementType": "geometry.stroke",
                        "stylers": [{"color": "#d7d7d7"}]
                    }, {
                        "featureType": "poi",
                        "elementType": "geometry.fill",
                        "stylers": [{"visibility": "on"}, {"color": "#ebebeb"}]
                    }, {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [{"color": "#a7a7a7"}]
                    }, {
                        "featureType": "road.arterial",
                        "elementType": "geometry.fill",
                        "stylers": [{"color": "#ffffff"}]
                    }, {
                        "featureType": "road.arterial",
                        "elementType": "geometry.fill",
                        "stylers": [{"color": "#ffffff"}]
                    }, {
                        "featureType": "landscape",
                        "elementType": "geometry.fill",
                        "stylers": [{"visibility": "on"}, {"color": "#efefef"}]
                    }, {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#696969"}]
                    }, {
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [{"visibility": "on"}, {"color": "#737373"}]
                    }, {
                        "featureType": "poi",
                        "elementType": "labels.icon",
                        "stylers": [{"visibility": "off"}]
                    }, {
                        "featureType": "poi",
                        "elementType": "labels",
                        "stylers": [{"visibility": "off"}]
                    }, {
                        "featureType": "road.arterial",
                        "elementType": "geometry.stroke",
                        "stylers": [{"color": "#d6d6d6"}]
                    }, {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [{"visibility": "off"}]
                    }, {}, {"featureType": "poi", "elementType": "geometry.fill", "stylers": [{"color": "#dadada"}]}];
                    var styledMap = new google.maps.StyledMapType(styles, {name: "tehgrayz"});
                    var mapOptions = {
                        center: new google.maps.LatLng(41.8043897, -87.9305616),
                        zoom: 16,
                        mapTypeControlOptions: {
                            mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
                        },
                        panControl: false,
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        overviewMapControl: false
                    };
                    var map = new google.maps.Map(document.getElementById("map"),
                        mapOptions);
                    var marker = new google.maps.Marker({
                        position: new google.maps.LatLng(41.8043897, -87.9305616),
                        icon: '/wp-content/themes/hinsdale-theme/dist/images/pin.png',
                    });
                    map.mapTypes.set('map_style', styledMap);
                    map.setMapTypeId('map_style');
                    marker.setMap(map);
                }

                initialize();

            },
        },

        // Art Page
        'page_template_template_art_exhibits': {
            init: function () {

                var art_swiper = new Swiper('#slider .swiper-container', {
                    slidesPerView: 1.55,
                    loop: true,
                    speed: 1200,
                    initialSlide: 2,
                    loopAdditionalSlides: 1,
                    centeredSlides: true,
                    spaceBetween: 35,
                    simulateTouch: false,
                    queueStartCallbacks: true,
                    queueEndCallbacks: true,
                    breakpoints: {
                        1980: {
                            spaceBetween: 30,
                        },
                        1540: {
                            spaceBetween: 25,
                        },
                        1280: {
                            spaceBetween: 20,
                        },
                        900: {
                            spaceBetween: 15,
                        },
                        768: {
                            slidesPerView: 1,
                            initialSlide: 1,
                            spaceBetween: 0,
                        },
                    },
                });
                $('.prev').on('click', function (e) {
                    e.preventDefault();
                    art_swiper.slidePrev();
                });
                $('.next').on('click', function (e) {
                    e.preventDefault();
                    art_swiper.slideNext();
                });

            },
        },

        // Art Page New
        'page_template_template_art_exhibits_new': {
            init: function () {

                var art_swiper = new Swiper('#slider .swiper-container', {
                    slidesPerView: 1.55,
                    loop: true,
                    speed: 1200,
                    initialSlide: 2,
                    loopAdditionalSlides: 1,
                    centeredSlides: true,
                    spaceBetween: 35,
                    simulateTouch: false,
                    queueStartCallbacks: true,
                    queueEndCallbacks: true,
                    breakpoints: {
                        1980: {
                            spaceBetween: 30,
                        },
                        1540: {
                            spaceBetween: 25,
                        },
                        1280: {
                            spaceBetween: 20,
                        },
                        900: {
                            spaceBetween: 15,
                        },
                        768: {
                            slidesPerView: 1,
                            initialSlide: 1,
                            spaceBetween: 0,
                        },
                    },
                });
                $('.prev').on('click', function (e) {
                    e.preventDefault();
                    art_swiper.slidePrev();
                });
                $('.next').on('click', function (e) {
                    e.preventDefault();
                    art_swiper.slideNext();
                });

            },
        },

        // Curated Page
        'page_template_template_interest_focused': {
            init: function () {

                $('.swiper-container').each(function () {
                    var that = $(this);
                    var swiper = new Swiper(that, {
                        slidesPerView: 5,
                        loop: true,
                        speed: 1200,
                        spaceBetween: 30,
                        simulateTouch: false,
                        queueStartCallbacks: true,
                        queueEndCallbacks: true,
                        breakpoints: {
                            960: {
                                slidesPerView: 3,
                            },
                            660: {
                                slidesPerView: 2,
                            },
                            480: {
                                slidesPerView: 1,
                            },
                        },
                    });
                    that.parent().find('.prev').on('click', function (e) {
                        e.preventDefault();
                        swiper.slidePrev();
                    });
                    that.parent().find('.next').on('click', function (e) {
                        e.preventDefault();
                        swiper.slideNext();
                    });
                })
            },
        },


        // Movies Page
        'page_template_template_movies': {
            init: function () {

                var movies_swiper = new Swiper('#tv-movies .swiper-container', {
                    slidesPerView: 5,
                    loop: true,
                    speed: 1200,
                    spaceBetween: 30,
                    simulateTouch: false,
                    queueStartCallbacks: true,
                    queueEndCallbacks: true,
                    breakpoints: {
                        960: {
                            slidesPerView: 3,
                        },
                        660: {
                            slidesPerView: 2,
                        },
                        480: {
                            slidesPerView: 1,
                        },
                    },
                });
                $('#tv-movies .prev').on('click', function (e) {
                    e.preventDefault();
                    movies_swiper.slidePrev();
                });
                $('#tv-movies .next').on('click', function (e) {
                    e.preventDefault();
                    movies_swiper.slideNext();
                });

                var coming_swiper = new Swiper('#coming-soon .swiper-container', {
                    slidesPerView: 5,
                    loop: true,
                    speed: 1200,
                    spaceBetween: 30,
                    simulateTouch: false,
                    queueStartCallbacks: true,
                    queueEndCallbacks: true,
                    breakpoints: {
                        960: {
                            slidesPerView: 3,
                        },
                        660: {
                            slidesPerView: 2,
                        },
                        480: {
                            slidesPerView: 1,
                        },
                    },
                });
                $('#coming-soon .prev').on('click', function (e) {
                    e.preventDefault();
                    coming_swiper.slidePrev();
                });
                $('#coming-soon .next').on('click', function (e) {
                    e.preventDefault();
                    coming_swiper.slideNext();
                });

            },
        },


        // Kids Page
        'page_template_template_audience_focused': {
            init: function () {

                var events_swiper = new Swiper('#slider .swiper-container', {
                    slidesPerView: 1.55,
                    loop: true,
                    speed: 1200,
                    initialSlide: 2,
                    loopAdditionalSlides: 1,
                    centeredSlides: true,
                    spaceBetween: 35,
                    simulateTouch: false,
                    queueStartCallbacks: true,
                    queueEndCallbacks: true,
                    breakpoints: {
                        1980: {
                            spaceBetween: 30,
                        },
                        1540: {
                            spaceBetween: 25,
                        },
                        1280: {
                            spaceBetween: 20,
                        },
                        900: {
                            spaceBetween: 15,
                        },
                        660: {
                            slidesPerView: 1,
                            initialSlide: 1,
                            spaceBetween: 0,
                        },
                    },
                });
                $('.prev').on('click', function (e) {
                    e.preventDefault();
                    events_swiper.slidePrev();
                });
                $('.next').on('click', function (e) {
                    e.preventDefault();
                    events_swiper.slideNext();
                });

                $(window).on('load', function () {
                    var $container = $('#isotope');
                    $container.isotope({
                        filter: '*',
                        itemSelector: '.item',
                        layoutMode: 'masonry',
                        masonry: {
                            columnWidth: 100,
                            gutter: 20
                        },
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false
                        }
                    });
                });

                var summer_swiper = new Swiper('#summer-reading .swiper-container', {
                    slidesPerView: 5,
                    loop: true,
                    speed: 1200,
                    spaceBetween: 30,
                    simulateTouch: false,
                    queueStartCallbacks: true,
                    queueEndCallbacks: true,
                    breakpoints: {
                        960: {
                            slidesPerView: 3,
                        },
                        660: {
                            slidesPerView: 2,
                        },
                        480: {
                            slidesPerView: 1,
                        },
                    },
                });
                $('#summer-reading .prev').on('click', function (e) {
                    e.preventDefault();
                    summer_swiper.slidePrev();
                });
                $('#summer-reading .next').on('click', function (e) {
                    e.preventDefault();
                    summer_swiper.slideNext();
                });

                var awards_swiper = new Swiper('#kids-awards .swiper-container', {
                    slidesPerView: 5,
                    loop: true,
                    speed: 1200,
                    spaceBetween: 30,
                    simulateTouch: false,
                    queueStartCallbacks: true,
                    queueEndCallbacks: true,
                    breakpoints: {
                        960: {
                            slidesPerView: 3,
                        },
                        660: {
                            slidesPerView: 2,
                        },
                        480: {
                            slidesPerView: 1,
                        },
                    },
                });
                $('#kids-awards .prev').on('click', function (e) {
                    e.preventDefault();
                    awards_swiper.slidePrev();
                });
                $('#kids-awards .next').on('click', function (e) {
                    e.preventDefault();
                    awards_swiper.slideNext();
                });

            },
        },


        // Devices Page
        'page_template_template_accordion': {
            init: function () {

                $('.toggle-link').on('click', function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('active')) {
                        $(this).removeClass('active');
                        $(this).next().slideUp(600);
                    } else {
                        $(this).addClass('active');
                        $(this).next().slideDown(600);
                    }
                });

            },
        },

        // Digital Page
        'page_template_template_digital_resources': {
            init: function () {
                /*window.onscroll = function() {
                    var scrollTop = $(window).scrollTop();
                    if( scrollTop > 100 ) {
                            if (!$('#barcode-form').hasClass('scroll')) {
                                $('#barcode').addClass('error');
                                $('.info-error').css('display', 'block');
                                $('#barcode-form').addClass('scroll');
                                $('html, body').animate({
                                    scrollTop: $($('#barcode-form')).offset().top
                                }, 500);
                            }
                    }
                }
                $('#barcode').focus(function() {
                      $(this).removeClass("error");
                });*/

            },
        },
        // News
        'page_template_template_news': {
            init: function () {

                function loadNews() {
                    var btn = $('.load-btn');
                    var page = btn.data('page');
                    var url = btn.data('url');
                    var query = $('#news-form input[name=query]').val();
                    var cat = $('#news-form select[name=cat]').val();

                    $.ajax({
                        type: 'GET',
                        url: url,
                        data: {page: page, query: query, cat: cat},
                        success: function (data) {
                            if (data !== '' && typeof data != 'undefined') {
                                $('#posts').append(data);

                                if ($('#posts .row').length < 3){
                                    btn.hide();
                                }
                                else {
                                    btn.data('page', page + 1);
                                }
                            }
                            else {
                                btn.hide();
                            }
                        }
                    })
                }

                $('#news-form').on('submit', function () {
                    $('#posts').html('');
                    $('.load-btn').show();
                    $('.load-btn').data('page', 1);
                    loadNews();
                    return false;
                });

                $('#news-form select').on('change', function () {
                    $('#news-form').submit();
                })

                $(window).on('load', function () {
                    loadNews();
                });

                $('.load-btn').click(function () {
                    loadNews();
                });
            }
        },

        // Events Page
        'page_template_template_events': {
            init: function () {

                var url = $('.filter-form').attr('action');
                var selectedDate = null;
                var selectedFormattedDate = null;

                loadTypes();

                /*$('.datepicker-here').datepicker({
                 dateFormat: 'yyyy-mm-dd',
                 language: {
                 daysMin: ["S", "M", "T", "W", "Th", "F", "S"],
                 monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                 months: ['January','February','March','April','May','June', 'July','August','September','October','November','December'],
                 },
                 onSelect: function(formattedDate, date, inst){
                 selectedDate = date;
                 selectedFormattedDate = formattedDate;

                 var momentSelectedDate = moment(selectedDate);

                 var string = 'Happening';

                 if (momentSelectedDate.isSame(moment.now(), 'day')){
                 string += ' today,';
                 }

                 string += momentSelectedDate.format(' dddd, MMMM Do');

                 $('.event-day .day p').html(string);

                 loadEvents();
                 }
                 });

                 var datepicker = $('.datepicker-here').data('datepicker');
                 datepicker.selectDate(new Date());*/

                $('.filter-form select').on('change', function () {
                    var type = $('.filter-form select').val();
                    if (type !== '') {
                        $('.breadcrumbs .current').html(type);
                    }
                    else {
                        $('.breadcrumbs .current').html('All');
                    }
                    loadEvents();
                })

                $('.filter-form').on('submit', function (e) {
                    e.preventDefault();
                    loadEvents();
                    return false;
                });

                $('input[type=date]').change(function () {
                    loadEvents();
                })


                function loadEvents() {

                    var start_date = moment($('input[name=start_date]').val());
                    var end_date = moment($('input[name=end_date]').val());

                    $('.event-day .day p').html('From ' + start_date.format('L') + ' to ' + end_date.format('L'));

                    $.ajax({
                        type: 'GET',
                        url: url + '/get-events.php',
                        data: {
                            start_date: start_date.format('YYYY-MM-DD'),
                            end_date: end_date.format('YYYY-MM-DD'),
                            type: $('.filter-form select.types').val(),
							age: $('.filter-form select.ages').val(),
                            query: $('.filter-form input[name="query"]').val()
                        },
                        beforeSend: function () {
                            $(".loading").show();
                        },
                        complete: function () {
                            $(".loading").hide();
                        },
                        success: function (data) {
                            if (data !== '' && typeof data != 'undefined') {
                                var response = jQuery.parseJSON(data);
                                $('.events-list').html('');

                                if (response && response.status == 'success' && response.data && response.data.entries && response.data.entries.length > 0) {
                                    for (var i = 0; i < response.data.entries.length; i++) {

                                        var event = response.data.entries[i];

                                        var eventStart = moment(event.eventStart);
                                        var eventEnd = moment(event.eventEnd);

                                        var inRoom = '';

                                        if (typeof event.roomName != 'undefined' && event.roomName != null) {
                                            inRoom = ' in ' + event.roomName;
                                        }

                                        $('.events-list').append('<div class="event"><div class="cnt"><p class="title">' + event.title + '</p><p class="time-place">' + eventStart.format(' dddd, MMMM Do') + '</p><p class="time-place">' + eventStart.format('h:mm A') + ' - ' + eventEnd.format('h:mm A') + inRoom + '</p>' + event.description + '<p><a target="_blank" href="http://hinsdale.libnet.info/event/' + event.eventId + '" class="more">Register</a></p></div></div>')
                                    }
                                }
                                else {
                                    $('.events-list').html('<p>No events for the selected dates.</p>')
                                }
                            }
                            else {
                                $('.events-list').html('<p>No events for the selected dates.</p>')
                            }
                        },
                        error: function () {
                            $(".loading").hide();
                            $('.events-list').html('<p>No events for the selected dates.</p>')
                        }
                    })
                }

                function loadTypes() {
                    $.ajax({
                        type: 'GET',
                        url: url + '/get-types.php',
                        success: function (data) {
                            if (data !== '' && typeof data != 'undefined') {
                                var response = jQuery.parseJSON(data);
                                $('.filter-form select.types').html('<option value="">Event Type</option>');
                                if (response && response.status == 'success' && response.data && response.data.entries && response.data.entries.length > 0) {
                                    for (var i = 0; i < response.data.entries.length; i++) {

                                        var type = response.data.entries[i];

                                        $('.filter-form select.types').append('<option value="' + type.name + '">' + type.name + '</option>')
                                    }
                                }
                            }
                        }
                    })
					$.ajax({
                        type: 'GET',
                        url: url + '/get-ages.php',
                        success: function (data) {
                            if (data !== '' && typeof data != 'undefined') {
                                var response = jQuery.parseJSON(data);
                                $('.filter-form select.ages').html('<option value="">Age Group</option>');
                                if (response && response.status == 'success' && response.data && response.data.entries && response.data.entries.length > 0) {
                                    for (var i = 0; i < response.data.entries.length; i++) {
                                        var type = response.data.entries[i];

                                        $('.filter-form select.ages').append('<option value="' + type + '">' + type + '</option>')
                                    }
                                }
                            }
                        }
                    })
                }

                loadEvents();

                $(document).ready(function () {

                })
            },
        },

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
